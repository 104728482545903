import { useState } from 'react';
import type { UndecoratedSequence } from '@witmetrics/api-client';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import { useDispatch } from '@/store/useStore';
import { addSequence as dispatchAddSequence } from '@/store/slices/sequencesSlice';
import { addSequenceStep as dispatchAddSequenceStep } from '@/store/slices/sequenceStepsSlice';
import { addFileCollectionSequence as dispatchAddFileCollectionSequence } from '@/store/slices/fileCollectionSequencesSlice';
import TextField from '@/components/TextField';
import {
  classes,
  createNewSequence,
  isDisabled,
  validateFields,
} from './utils';

type NewSequenceDialogProps = {
  fileCollectionID: number;
  onSave?: (sequence: UndecoratedSequence) => void;
  onClose: () => void;
};

export default function NewSequenceDialog({
  fileCollectionID,
  onSave,
  onClose,
}: NewSequenceDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSaving, toggleSaving] = useToggle(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const handleSave = async () => {
    try {
      toggleSaving(true);
      const invalid = validateFields(name);
      if (invalidFields.length > 0) return setInvalidFields(invalid);
      const data = await createNewSequence(name, description, fileCollectionID);
      dispatch(dispatchAddSequence(data.sequence));
      dispatch(dispatchAddSequenceStep(data.sequenceStep));
      dispatch(dispatchAddFileCollectionSequence(data.fileCollectionSequence));
      if (onSave) onSave(data.sequence);
      toggleSaving(false);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error creating sequence');
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="New file sequence" onClose={onClose} />
      <DialogBody className={classes.body}>
        <TextField
          error={invalidFields.includes('name')}
          className={classes.input}
          name="name"
          label="Name"
          value={name}
          onChange={({ name }) => setName(name)}
        />
        <TextField
          multiline
          minRows={3}
          maxRows={5}
          className={classes.input}
          name="description"
          label="Description (optional)"
          value={description}
          onChange={({ description }) => setDescription(description)}
        />
      </DialogBody>
      <DefaultDialogActions
        disabled={isDisabled(name)}
        pending={isSaving}
        confirmLabel="Save"
        pendingLabel="Saving..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
