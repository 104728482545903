import { API } from '@/api';

export const classes = {
  dialog: 'w-[98vw] max-w-[450px] min-w-[350px]',
  body: 'w-full max-h-[80vh] min-h-[100px] pt-2 pb-12 overflow-auto',
  name: 'w-full my-4',
  description: 'w-full mb-8',
};

export async function createStep(
  sequenceID: number,
  data: {
    name: string;
    description: string;
  }
) {
  const position = await fetchStepPosition(sequenceID);
  return API.Sequences.addSequenceStep(sequenceID, {
    ...buildNameDescription(data.name, data.description),
    position,
  });
}

export function updateStep(
  stepID: number,
  data: { name: string; description: string }
) {
  return API.Sequences.updateSequenceStep(
    stepID,
    buildNameDescription(data.name, data.description)
  );
}

async function fetchStepPosition(sequenceID: number) {
  const steps = await API.Sequences.fetchSequenceSteps(sequenceID, {
    pagination: { page: 1, pageSize: 100 }, // TODO
    filters: [],
  });
  if (steps.results.length === 0) return 0;
  // Since results array is 0-indexed, the next position is results.length
  return Math.max(steps.results.length, 0);
}

function buildNameDescription(name: string, description: string) {
  return {
    name: name.trim().length > 0 ? name.trim() : null,
    description: description.trim().length > 0 ? description.trim() : null,
  };
}
