import { isBlank } from '@/utils/strings';
import { API } from '@/api';

export const classes = {
  dialog: 'w-[98vw] max-w-[450px] min-w-[350px]',
  body: 'w-full max-h-[80vh] min-h-[100px] pt-2 pb-12 overflow-auto',
  input: 'w-full my-4',
};

export function validateFields(name: string) {
  let fields: string[] = [];
  if (isBlank(name)) fields.push('name');
  return fields;
}

export function isDisabled(name: string) {
  return isBlank(name);
}

export async function createNewSequence(
  name: string,
  description: string,
  fileCollectionID: number
) {
  const sequence = await API.Sequences.createNewSequence({
    name,
    description,
  });
  const [fileCollectionSequence, sequenceStep] = await Promise.all([
    API.FileCollections.addFileCollectionSequence(
      fileCollectionID,
      sequence.id
    ),
    API.Sequences.addSequenceStep(sequence.id, {
      position: 0,
      name: null,
      description: null,
    }),
  ]);
  return { sequence, fileCollectionSequence, sequenceStep };
}
