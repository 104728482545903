import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import { API } from '@/api';
import { useDispatch } from '@/store/useStore';
import { deleteSequenceStep as dispatchDeleteSequenceStep } from '@/store/slices/sequenceStepsSlice';
import { showSuccessToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { classes } from './utils';

export type DeleteSequenceStepDialogProps = {
  stepID: number;
  onDelete?: (stepID: number) => void;
  onClose: () => void;
};

export default function DeleteSequenceStepDialog({
  stepID,
  onDelete,
  onClose,
}: DeleteSequenceStepDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const [isDeleting, toggleDeleting] = useToggle(false);

  const handleDelete = async () => {
    try {
      toggleDeleting(true);
      await API.Sequences.removeSequenceStep(stepID);
      dispatch(dispatchDeleteSequenceStep({ stepID }));
      if (onDelete) onDelete(stepID);
      showSuccessToaster('Step deleted');
      toggleDeleting(false);
      onClose();
    } catch (err) {
      toggleDeleting(false);
      onApiError(err, 'Error deleting step', () => {
        handleDelete();
      });
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="Delete step" onClose={onClose} />
      <DialogBody className={classes.body}>
        Are you sure you want to remove this step?
        <br />
        All files in this step will also be deleted.
        <br />
        This action cannot be undone.
      </DialogBody>
      <DefaultDialogActions
        disabled={isDeleting}
        pending={isDeleting}
        confirmLabel="Delete"
        pendingLabel="Deleting..."
        onCancel={onClose}
        onConfirm={handleDelete}
      />
    </Dialog>
  );
}
