import { useEffect, useState } from 'react';
import { useFilesByStepID, useStep } from '@/store/useStore';
import ViewerContent from './ViewerContent';
import FileTree from './FileTree';
import StepTitle, { type StepTitleProps } from './StepTitle';
import { mapToID, mapToKey, mergeWithoutDuplicates } from '@/utils/arrays';
import { formatTreeID, parseCompositeID } from '@/utils/files';
import { TREE_ITEM_TYPES } from '@/constants/treeItemTypes';
import { useToggle } from '@/hooks/useToggle';
import FileInfoDialog from '@/dialogs/FileInfoDialog';
import FileCollectionDialog from '@/dialogs/FileCollectionDialog';
import DeleteFileDialog from '@/dialogs/DeleteFileDialog';
import DeleteFileCollectionDialog from '@/dialogs/DeleteFileCollectionDialog';
import { classes, parseFiles } from './utils';

export type StepViewerProps = {
  className?: string;
  isLoading: boolean;
  stepID: number;
  onSaveFiles: StepTitleProps['onSaveFiles'];
  onSaveFileCollection: StepTitleProps['onSaveFileCollection'];
  onOpenFiles: (fileIDs: number[]) => void;
};

export default function StepViewer({
  className,
  isLoading,
  stepID,
  onSaveFiles,
  onSaveFileCollection,
  onOpenFiles,
}: StepViewerProps) {
  const step = useStep(stepID);
  const files = useFilesByStepID(stepID);
  const [openFileKeys, setOpenFileKeys] = useState<string[]>([]);
  const [activeFileID, setActiveFileID] = useState<number | null>(null);
  const [activeFileCollectionID, setActiveFileCollectionID] = useState<
    number | null
  >(null);
  const [isEditingFile, toggleEditingFile] = useToggle(false);
  const [isEditingFileCollection, toggleEditingFileCollection] =
    useToggle(false);
  const [isDeletingFile, toggleDeletingFile] = useToggle(false);
  const [isDeletingFileCollection, toggleDeletingFileCollection] =
    useToggle(false);

  useEffect(() => {
    // NOTE: Default to opening all files when the step is loaded
    handleOpenStepFiles();
  }, [stepID, files]);

  const handleOpenStepFiles = () => {
    const parsedFiles = parseFiles(files);
    setOpenFileKeys(mapToKey(parsedFiles, 'fileKey'));
    onOpenFiles(mapToID(parsedFiles));
  };

  const handleCloseFile = () => {
    const fileKey = formatTreeID(activeFileID!, TREE_ITEM_TYPES.FILE);
    setOpenFileKeys(openFileKeys.filter((key) => key !== fileKey));
    handleEndDeleteFile();
  };

  const handleCloseFileCollection = () => {
    // TODO
    handleEndDeleteCollection();
  };

  const handleDeleteStep = () => {
    // TODO: navigate back to the previous step
  };

  const handleOpenFile = (fileKey: string) => {
    if (openFileKeys.includes(fileKey)) {
      setOpenFileKeys(openFileKeys.filter((key) => key !== fileKey));
    } else {
      onOpenFiles([parseCompositeID(fileKey)]);
      setOpenFileKeys(mergeWithoutDuplicates(openFileKeys, [fileKey]));
    }
  };

  const handleOpenCollection = (fileKeys: string[]) => {
    // TODO: Can this be cleaner?
    fileKeys.forEach(handleOpenFile);
  };

  const handleStartEditFile = (fileKey: string) => {
    setActiveFileID(parseCompositeID(fileKey));
    toggleEditingFile(true);
  };

  const handleStartEditCollection = (fileCollectionID: number) => {
    setActiveFileCollectionID(fileCollectionID);
    toggleEditingFileCollection(true);
  };

  const handleStartDeleteFile = (fileKey: string) => {
    setActiveFileID(parseCompositeID(fileKey));
    toggleDeletingFile(true);
  };

  const handleStartDeleteCollection = (fileCollectionID: number) => {
    setActiveFileCollectionID(fileCollectionID);
    toggleDeletingFileCollection(true);
  };

  const handleEndEditFile = () => {
    toggleEditingFile(false);
    setActiveFileID(null);
  };

  const handleEndEditCollection = () => {
    toggleEditingFileCollection(false);
    setActiveFileCollectionID(null);
  };

  const handleEndDeleteFile = () => {
    toggleDeletingFile(false);
    setActiveFileID(null);
  };

  const handleEndDeleteCollection = () => {
    toggleDeletingFileCollection(false);
    setActiveFileCollectionID(null);
  };

  if (!step) return null; // TODO: Display a message

  return (
    <>
      <div className={className}>
        <div className={classes.sidebar}>
          <StepTitle
            isLoading={isLoading}
            stepID={stepID}
            onSaveFiles={onSaveFiles}
            onSaveFileCollection={onSaveFileCollection}
            onDelete={handleDeleteStep}
          />
          <FileTree
            stepID={stepID}
            openFileKeys={openFileKeys}
            onOpenFile={handleOpenFile}
            onOpenCollection={handleOpenCollection}
            onEditFile={handleStartEditFile}
            onEditCollection={handleStartEditCollection}
            onDeleteFile={handleStartDeleteFile}
            onDeleteCollection={handleStartDeleteCollection}
          />
        </div>
        <ViewerContent isLoading={isLoading} openFileKeys={openFileKeys} />
      </div>
      {isEditingFile && (
        <FileInfoDialog fileID={activeFileID!} onClose={handleEndEditFile} />
      )}
      {isEditingFileCollection && (
        <FileCollectionDialog
          fileCollectionID={activeFileCollectionID!}
          onClose={handleEndEditCollection}
        />
      )}
      {isDeletingFile && (
        <DeleteFileDialog
          fileID={activeFileID!}
          onDelete={handleCloseFile}
          onClose={handleEndDeleteFile}
        />
      )}
      {isDeletingFileCollection && (
        <DeleteFileCollectionDialog
          fileCollectionID={activeFileCollectionID!}
          onDelete={handleCloseFileCollection}
          onClose={handleEndDeleteCollection}
        />
      )}
    </>
  );
}
