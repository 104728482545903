import { useEffect, useState } from 'react';
import { DecoratedSequenceStep } from '@witmetrics/api-client';
import { useDispatch, useStep } from '@/store/useStore';
import {
  addSequenceStep as dispatchAddSequenceStep,
  updateSequenceStep as dispatchUpdateSequenceStep,
} from '@/store/slices/sequenceStepsSlice';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import TextField from '@/components/TextField';
import { showErrorToaster, showSuccessToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { classes, createStep, updateStep } from './utils';

export type SequenceStepDialogProps = {
  sequenceID?: number;
  stepID?: number;
  onSave?: (step: DecoratedSequenceStep) => void;
  onClose: () => void;
};

export default function SequenceStepDialog({
  sequenceID,
  stepID,
  onClose,
  onSave,
}: SequenceStepDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const step = useStep(stepID);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSaving, toggleSaving] = useToggle(false);

  useEffect(() => {
    if (step) fillFields();
  }, [step]);

  const fillFields = () => {
    setName(step!.name || '');
    setDescription(step!.description || '');
  };

  const handleSave = async () => {
    const errorMessage = 'Error saving step';
    try {
      toggleSaving(true);
      const data = { name, description };
      if (!stepID && !sequenceID) {
        showErrorToaster(errorMessage);
        toggleSaving(false);
        throw Error(errorMessage);
      } else if (stepID) {
        const updatedStep = await updateStep(stepID, data);
        dispatch(dispatchUpdateSequenceStep(updatedStep));
        showSuccessToaster('Step updated');
        if (onSave) onSave(updatedStep);
      } else {
        const newStep = await createStep(sequenceID!, data);
        dispatch(dispatchAddSequenceStep(newStep));
        showSuccessToaster('Step added');
        if (onSave) onSave(newStep);
      }
      toggleSaving(false);
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, errorMessage, () => handleSave());
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle
        title={stepID ? 'Edit Step' : 'New Step'}
        onClose={onClose}
      />
      <DialogBody className={classes.body}>
        <TextField
          autoFocus
          className={classes.name}
          name="name"
          label="Name (optional)"
          value={name}
          onChange={(update) => setName(update.name)}
        />
        <TextField
          multiline
          className={classes.description}
          minRows={3}
          maxRows={5}
          name="description"
          label="Description (optional)"
          value={description}
          onChange={(update) => setDescription(update.description)}
        />
      </DialogBody>
      <DefaultDialogActions
        disabled={isSaving}
        pending={isSaving}
        confirmLabel="Save"
        pendingLabel="Saving..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
