import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setActiveAccount } from './activeAccountSlice';
import { deleteSequence } from './sequencesSlice';
import {
  DecoratedSequenceStep,
  UndecoratedSequenceStep,
} from '@witmetrics/api-client';
import { addToIDArray } from '../utils/stateFormatting';

type SequenceStepsState = {
  byID: Record<string, UndecoratedSequenceStep>;
  bySequenceID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  bySequenceID: {},
} satisfies SequenceStepsState as SequenceStepsState;

export const sequenceStepsSlice = createSlice({
  name: 'sequenceSteps',
  initialState,
  reducers: {
    setSequenceSteps: (
      state,
      action: PayloadAction<DecoratedSequenceStep[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addSequenceStep: (state, action: PayloadAction<DecoratedSequenceStep>) => {
      return getNextState(state, [action.payload]);
    },
    updateSequenceStep: (
      state,
      action: PayloadAction<DecoratedSequenceStep>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteSequenceStep: (state, action: PayloadAction<{ stepID: number }>) => {
      const key = `${action.payload.stepID}`;
      if (!state.byID[key]) return state;
      const sequenceID = state.byID[key].sequenceID;
      if (state.bySequenceID[sequenceID]) {
        state.bySequenceID[sequenceID] = state.bySequenceID[sequenceID].filter(
          (id) => id !== key
        );
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, bySequenceID: {} };
      })
      .addCase(deleteSequence, (state, action) => {
        const sequenceID = action.payload.sequenceID;
        const stepIDs = state.bySequenceID[sequenceID] || [];
        stepIDs.forEach((stepID) => {
          delete state.byID[stepID];
        });
        delete state.bySequenceID[sequenceID];
      })
      .addDefaultCase(() => {});
  },
});

function formatSequenceStep({
  files,
  fileCollections,
  ...sequenceStep
}: DecoratedSequenceStep) {
  return sequenceStep;
}

function getNextState(
  state: SequenceStepsState,
  sequenceSteps: DecoratedSequenceStep[]
) {
  let byID = { ...state.byID };
  let bySequenceID = { ...state.bySequenceID };
  sequenceSteps.forEach((step) => {
    const key = `${step.id}`;
    byID[key] = formatSequenceStep(step);
    bySequenceID[step.sequenceID] = addToIDArray(
      bySequenceID[step.sequenceID],
      key
    );
  });
  return { byID, bySequenceID };
}

export const {
  setSequenceSteps,
  addSequenceStep,
  updateSequenceStep,
  deleteSequenceStep,
} = sequenceStepsSlice.actions;

export default sequenceStepsSlice.reducer;
